<template>
	<div class="px-4 wrapper">
		<v-container
			class="pa-0"
			fluid
		>
			<div class="register">
				<v-col
					align="center"
					class="pa-0"
				>
					<h2 class="content_title">케어플로(Care-Flo) 서비스</h2>
				</v-col>

				<v-col
					class="pa-4 pa-sm-6 layout_align section body"
					sm="9"
					cols="12"
				>
					<h3 class="mb-10">A/S 신청하기</h3>

					<div class="input_area">
						<div class="table">
							<v-row class="ma-0 mb-3">
								<v-col
									class="pa-0 pb-1 pb-md-0 th"
									cols="12"
									md="2"
									align="start"
									align-self="center"
								>
									<p class="required">제목</p>
								</v-col>
								<v-col
									class="pa-0 td"
									cols="12"
									md="10"
									align="start"
								>
									<v-col
										cols="12"
										class="pa-0"
									>
										<CommonInputsInput03
											v-model="asTitle"
											placeholder="제목을 입력해 주세요"
											class="mb-2"
										/>
									</v-col>
								</v-col>
							</v-row>

							<v-row class="ma-0 mb-3">
								<v-col
									class="pa-0 pb-1 pb-md-0 th"
									cols="12"
									md="2"
									align="start"
								>
									<p class="required">A/S접수 내용</p>
								</v-col>
								<v-col
									class="pa-0 td"
									cols="12"
									md="10"
									align="start"
								>
									<v-col class="pa-0">
										<v-textarea
											v-model="asContents"
											outlined
											hide-details
											placeholder="내용을 입력해주세요"
										/>
									</v-col>
								</v-col>
							</v-row>

							<v-row class="ma-0 mb-3">
								<v-col
									class="pa-0 pb-1 pb-md-0 th"
									cols="12"
									md="2"
									align="start"
								>
									첨부파일
								</v-col>
								<v-col
									class="pa-0 td"
									cols="12"
									md="10"
									align="start"
								>
									<v-row class="ma-0">
										<CommonInputsInputFile01
											v-model="uploadFile"
											placeholder="jpg / png / gif 이미지 * 파일제한: 3MB 이하 첨부 가능"
										/>
									</v-row>
								</v-col>
							</v-row>

							<v-row class="ma-0 mb-3">
								<v-col
									class="pa-0 pb-1 pb-md-0 th"
									cols="12"
									md="2"
									align="start"
								>
									<p class="required">개인정보 처리약관</p>
								</v-col>
								<v-col
									class="pa-0 td"
									cols="12"
									md="10"
								>
									<div class="btn_privacy">
										<div>
											<CommonCheckboxsCheckbox02
												v-model="privateTermsCheck"
												text="개인정보 이용에 동의"
												class="pa-0 ma-0 pr-5"
												:block="false"
											/>
										</div>
										<div>
											<v-btn
												text
												@click="common_openPrivate"
											>
												개인정보처리방침확인
											</v-btn>
										</div>
									</div>
								</v-col>
							</v-row>
						</div>
					</div>
				</v-col>
			</div>
		</v-container>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import common from '@/mixins/common'
import model from '@/mixins/model'
export default {
	name: 'CareFloCustomerAsRegister',
	mixins: [common, model],
	data: () => ({
		uploadFile: null,
	}),
	computed: {
		...mapGetters(['CAREFLO_GET_AUTH_USER']),
	},
	watch: {},
	mounted() {},
	methods: {},
}
</script>

<style scoped lang="scss">
.section {
	background-color: $color_white;
	border-radius: 16px;
	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
}

// 개인정보 처리약관
.btn_privacy {
	div {
		float: left;
	}
	div + div {
		margin-left: 30px;
		:deep(.v-btn__content) {
			border-bottom: 1px solid $color_font;
		}
		.v-btn:not(.v-btn--round).v-size--default {
			padding: 0 !important;
		}
	}
}
@media all and (max-width: 600px) {
	.btn_privacy {
		* {
			font-size: $font_sm;
		}
	}
}
</style>
